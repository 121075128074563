import { createTokens } from 'tamagui'

export default createTokens({
  color: {},
  space: {
    '-1': -6,
    '-0.5': -3,
    0: 0,
    0.5: 3,
    1: 6,
    1.5: 9,
    2: 12,
    2.5: 15,
    3: 18,
    3.5: 21,
    4: 24,
    4.5: 27,
    5: 30,
    5.5: 33,
    6: 36,
    7: 42,
    8: 48,
    9: 54,
    10: 60,
    11: 66,
    12: 72,
    true: '$2',
    sm: 24,
    md: 36,
    lg: 42,
  },
  size: {
    0: 0,
    true: '$0',
  },
  radius: {
    0: 0,
    1: 2,
    true: '$1',
  },
  zIndex: {
    0: 0,
    tableHeader: 1,
    floating: 2,
    navigation: 5,

    dropdown: 10_001,
    modal: 100_000,
    modalDropdown: 100_001,
    true: '$0',
  },
  fontSizes: {
    0: 0,
    true: '$0',
  },
})
