import { MarketId } from '../constants/markets'
import { CollateralId } from '../constants/tokens'

export enum OptionsChainColumn {
  OI = 'OI',
  IV = 'IV',
  Volume = 'Volume',
  Position = 'Position',
  BreakEven = 'BreakEven',
  ToBreakEven = 'ToBreakEven',
  Change = 'Change',
  PctChange = 'PctChange',
  Delta = 'Delta',
  Vega = 'Vega',
  Gamma = 'Gamma',
  Theta = 'Theta',
  Rho = 'Rho',
}

export enum OptionsChainPreset {
  Advanced = 'Advanced',
  Simple = 'Simple',
}

export enum TradeFormView {
  Orderbook = 'Orderbook',
  Payoff = 'Payoff',
  Trades = 'Trades',
}

export type OptionsChainDisplay = 'all' | 'calls' | 'puts'

export type SpotChartType = 'Candles' | 'Line'

export const OPTIONS_CUSTOM_COLUMN_MAX = 6
export const OPTIONS_CUSTOM_COLUMN_MIN = 2

export type OptionsChainSettings = {
  columns: OptionsChainColumn[]
  showBidAskSize: boolean
  showMarkPrice: boolean
  showBidAskIv: boolean
  defaultToCalls: boolean
}

export type Settings = {
  optionsChain: OptionsChainSettings
  tradeFormView: TradeFormView
  tradeHeaderChart: SpotChartType
  defaultPerpsMarket: MarketId
  defaultOptionsMarket: MarketId
  defaultSpotCollateral: CollateralId
  isMarqueePaused: boolean
}

const OPTIONS_CHAIN_SIMPLE_PRESET: OptionsChainSettings = {
  columns: [
    OptionsChainColumn.Position,
    OptionsChainColumn.BreakEven,
    OptionsChainColumn.ToBreakEven,
    OptionsChainColumn.Delta,
  ],
  showBidAskSize: false,
  showMarkPrice: false,
  showBidAskIv: false,
  defaultToCalls: true,
}

const OPTIONS_CHAIN_ADVANCED_PRESET: OptionsChainSettings = {
  columns: [OptionsChainColumn.Delta, OptionsChainColumn.IV, OptionsChainColumn.Volume],
  showBidAskSize: true,
  showMarkPrice: false,
  showBidAskIv: false,
  defaultToCalls: false,
}

export const DEFAULT_SETTINGS: Settings = {
  optionsChain: OPTIONS_CHAIN_ADVANCED_PRESET,
  tradeFormView: TradeFormView.Payoff,
  tradeHeaderChart: 'Candles',
  defaultOptionsMarket: MarketId.ETH,
  defaultPerpsMarket: MarketId.ETH,
  defaultSpotCollateral: CollateralId.ETH,
  isMarqueePaused: false,
}

export const getOptionsChainPreset = (preset: OptionsChainPreset): OptionsChainSettings => {
  switch (preset) {
    case OptionsChainPreset.Advanced:
      return OPTIONS_CHAIN_ADVANCED_PRESET
    case OptionsChainPreset.Simple:
      return OPTIONS_CHAIN_SIMPLE_PRESET
  }
}

export const isOptionsChainPreset = (
  settings: OptionsChainSettings
): OptionsChainPreset | undefined => {
  return Object.values(OptionsChainPreset).find(
    (preset) => JSON.stringify(settings) === JSON.stringify(getOptionsChainPreset(preset))
  )
}
