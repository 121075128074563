import DropdownButton, { DropdownButtonProps } from '@lyra/core/components/DropdownButton'
import React from 'react'
import { withStaticProperties } from 'tamagui'

export type NavDropdownButtonProps = Omit<DropdownButtonProps, 'strategy'>

const NavDropdownButton = ({ dropdownProps, ...dropdownButtonProps }: NavDropdownButtonProps) => {
  return (
    <DropdownButton
      {...dropdownButtonProps}
      strategy="fixed"
      dropdownProps={{ ...dropdownProps, zIndex: '$navigation' }}
    />
  )
}

export default withStaticProperties(NavDropdownButton, {
  ListItem: DropdownButton.ListItem,
  // Wrap a component with <NavDropdownButton.Close> in order to close on press
  Close: DropdownButton.Close,
})
