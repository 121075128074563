import useHover from '@lyra/core/hooks/useHover'
import type { IconProps as SVGIconProps } from '@tamagui/helpers-icon'
import React from 'react'
import { Anchor } from 'tamagui'

import { TextColor } from '../utils/text'
import Icon from './Icon'
import { LinkProps } from './Link'

type Props = {
  icon: React.ReactElement<SVGIconProps>
  size?: number | string
  color?: TextColor
} & Omit<LinkProps, 'label' | 'size'>

const LinkIcon = React.forwardRef<HTMLAnchorElement, Props>(
  (
    { icon, size, onPress, href, target, color: colorInput, isActive: isActiveInput, ...props },
    ref
  ) => {
    const { isHovering, handleHoverIn, handleHoverOut } = useHover()

    const isActive = isHovering || isActiveInput
    const color = isActive ? '$primaryText' : colorInput ? colorInput : '$secondaryText'

    return (
      <Anchor
        onPress={onPress}
        onHoverIn={handleHoverIn}
        onHoverOut={handleHoverOut}
        href={href ? href : undefined}
        target={target}
        cursor="pointer"
        alignItems="center"
        display="inline-flex"
        ref={ref}
        width={size}
        height={size}
        {...props}
      >
        <Icon color={color} icon={icon} size={size} />
      </Anchor>
    )
  }
)

LinkIcon.displayName = 'Link'

export default LinkIcon
