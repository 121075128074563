import Responsive from '@lyra/core/components/Responsive'

import NavDesktopHeader from './NavDesktopHeader'
import NavMarquee from './NavMarquee'
import NavMobileHeader from './NavMobileHeader'
import NavMobileTabs from './NavMobileTabs'

export default function Nav() {
  return (
    <>
      <Responsive
        width="100%"
        backgroundColor="$appBg"
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          overflowY: 'hidden',
        }}
        zIndex={10}
        desktop={<NavDesktopHeader />}
        mobile={<NavMobileHeader />}
      />
      <Responsive mobile={<NavMobileTabs />} />
      <Responsive
        width="100%"
        backgroundColor="$appBg"
        borderColor="$hairline"
        borderTopWidth={1}
        style={{
          position: 'fixed',
          bottom: 0,
          left: 0,
          right: 0,
          overflowY: 'hidden',
        }}
        zIndex={10}
        desktop={<NavMarquee />}
      />
    </>
  )
}
