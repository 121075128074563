import React, { SVGProps } from 'react'

export function Warpcast(props: SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="180 180 900 900" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M826.513 398.633L764.404 631.889L702.093 398.633H558.697L495.789 633.607L433.087 398.633H269.764L421.528 914.36H562.431L629.807 674.876L697.181 914.36H838.388L989.819 398.633H826.513Z"
        fill="currentColor"
      ></path>
    </svg>
  )
}
