export default {
  amber_1: '#251D0D',
  amber_2: '#402F10',
  amber_3: '#5B4213',
  amber_4: '#765416',
  amber_5: '#906718',
  amber_6: '#AB791B',
  amber_7: '#C68C1E',
  amber_8: '#E19E21',
  amber_9: '#FCB124',
  amber_10: '#F7BF54',
  amber_11: '#F3CD83',
  amber_12: '#EEDAB3',
}
