import { styled } from 'tamagui'

import Page from '../Page'

const PageNarrow = styled(Page, {
  width: 480,
  maxWidth: '100%',
  paddingVertical: '$6',
  borderRightWidth: 1,
  borderLeftWidth: 1,
  borderColor: '$hairline',
  height: 'fit-content',
  $mobile: {
    paddingVertical: '0px',
  },
})

export default PageNarrow
