import VideoLink, { VideoLinkProps } from '@lyra/core/components/VideoLink'
import { Pathname } from '@lyra/web/constants/pages'
import RouteLink from 'next/link'
import { useRouter } from 'next/navigation'
import { useEffect } from 'react'

type Props = {
  href: Pathname
} & Omit<VideoLinkProps, 'href' | 'target'>

const NextVideoLink = ({ href, ...linkProps }: Props) => {
  const { prefetch } = useRouter()

  useEffect(() => {
    prefetch(href)
  }, [prefetch, href])

  return (
    <RouteLink href={href} style={{ textDecoration: 'none' }} legacyBehavior>
      <VideoLink href={href} {...linkProps} />
    </RouteLink>
  )
}

export default NextVideoLink
