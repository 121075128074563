export default {
  green_1: '#0B231A',
  green_2: '#0C3D2A',
  green_3: '#0D563A',
  green_4: '#0E6F4A',
  green_5: '#0F895A',
  green_6: '#10A26A',
  green_7: '#11BB7A',
  green_8: '#12D58A',
  green_9: '#13EE9A',
  green_10: '#49EDAC',
  green_11: '#7EEBBE',
  green_12: '#B4EAD0',
}
