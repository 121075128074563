export default {
  blue_1: '#0E1720',
  blue_2: '#122436',
  blue_3: '#16304D',
  blue_4: '#1A3D63',
  blue_5: '#1D4A79',
  blue_6: '#21578F',
  blue_7: '#2563A6',
  blue_8: '#2970BC',
  blue_9: '#2D7DD2',
  blue_10: '#5C98D6',
  blue_11: '#8BB3DA',
  blue_12: '#BACDDE',
}
