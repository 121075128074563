import { LOCAL_STORAGE_TRADER_SETTINGS_KEY } from '@lyra/web/constants/localStorage'
import { DEFAULT_SETTINGS, Settings } from '@lyra/web/utils/settings'
import { useCallback, useMemo } from 'react'

import useLocalStorage from './local_storage/useLocalStorage'

export default function useSettings(): [
  Settings,
  (setSettings: (prevSettings: Settings) => Settings) => void,
] {
  const [_settings, _setSettings] = useLocalStorage(LOCAL_STORAGE_TRADER_SETTINGS_KEY)

  const settings: Settings = useMemo(() => {
    try {
      const parsedSettings = _settings ? JSON.parse(_settings) : {}
      return { ...DEFAULT_SETTINGS, ...parsedSettings }
    } catch (e) {
      console.error('Failed to parse settings from localStorage:', e)
      return DEFAULT_SETTINGS
    }
  }, [_settings])

  const setSettings = useCallback(
    (setSettingsFn: (prevSettings: Settings) => Settings) => {
      const newSettings = setSettingsFn(settings)
      _setSettings(JSON.stringify(newSettings))
    },
    [_setSettings, settings]
  )

  return useMemo(() => [settings, setSettings], [setSettings, settings])
}
