import { ButtonProps } from '@lyra/core/components/Button'
import { Code } from '@lyra/core/components/Icon'
import Spinner from '@lyra/core/components/Spinner'
import NavDropdownButton from '@lyra/web/components/common/NavDropdownButton'
import useAdmin from '@lyra/web/hooks/useAdmin'
import useAuth from '@lyra/web/hooks/useAuth'
import React, { useState } from 'react'
import { isAddress } from 'viem'

type Props = ButtonProps

const NavAdminToolsDropdownButton = ({ ...buttonProps }: Props) => {
  const { isAdmin } = useAdmin()
  const [isLoading, setIsLoading] = useState(false)
  const { createMockSessionDONOTUSE } = useAuth()
  if (!isAdmin) {
    return null
  }

  return (
    <NavDropdownButton icon={isLoading ? <Spinner size={20} /> : <Code />} {...buttonProps}>
      <NavDropdownButton.ListItem
        label="View As"
        sublabel="Sign in as another user"
        onPress={async () => {
          const address = prompt('Address')
          if (!address || !isAddress(address)) {
            throw new Error('Invalid address')
          }
          setIsLoading(true)
          try {
            await createMockSessionDONOTUSE(address)
          } catch (e) {
            console.error(e)
          } finally {
            setIsLoading(false)
          }
        }}
      />
    </NavDropdownButton>
  )
}

export default NavAdminToolsDropdownButton
