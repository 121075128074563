import React from 'react'
import { StackProps, XStack } from 'tamagui'

import { TextColor } from '../utils/text'
import BodyText from './BodyText'
import LabelText from './LabelText'

type Props = {
  // TODO: @earthtojake support tooltips
  label: React.ReactNode
  value: React.ReactNode
  color?: TextColor
} & StackProps

export default function DataLabelRow({ label, value, color, ...stackProps }: Props) {
  return (
    <XStack justifyContent="space-between" alignItems="center" {...stackProps}>
      <LabelText>{label}</LabelText>
      <BodyText color={color}>{value}</BodyText>
    </XStack>
  )
}
