import useAuth from '@lyra/web/hooks/useAuth'
import useSubaccount from '@lyra/web/hooks/useSubaccount'
import useTransaction from '@lyra/web/hooks/useTransaction'
import { useMemo } from 'react'

import useTotalAccountValue from './useTotalAccountValue'

// useful flags to determine account state
type NewAccount = {
  isLoading: boolean
  error?: Error
  isNoAuth?: boolean
  isAuthWithNoFirstDeposit?: boolean // user has not deposited
  isAuthWithNoFirstTransfer?: boolean // user has not created first subaccount
}

export default function useNewAccount(): NewAccount {
  const { isAuthenticated } = useAuth()
  const { subaccount } = useSubaccount()
  const { pendingBridges } = useTransaction()

  const { data, isLoading, error } = useTotalAccountValue()
  const totalAccountValue = data?.totalValue ?? 0
  const fundingAccountValue = data?.fundingAccountValue ?? 0

  const isNoAuth = !isAuthenticated

  // user is authenticated with no subaccount or account value or pending bridges
  const isAuthWithNoFirstDeposit =
    !isLoading && !error
      ? isAuthenticated && !subaccount && !totalAccountValue && !pendingBridges.length
      : undefined

  // user is authenticated with no subaccount and non-zero funding account value
  const isAuthWithNoFirstTransfer =
    !isLoading && !error ? isAuthenticated && !subaccount && !!fundingAccountValue : undefined

  return useMemo(
    () => ({
      isLoading,
      error,
      isNoAuth,
      isAuthWithNoFirstDeposit,
      isAuthWithNoFirstTransfer,
    }),
    [isLoading, error, isNoAuth, isAuthWithNoFirstDeposit, isAuthWithNoFirstTransfer]
  )
}
