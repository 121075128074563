import { useMemo } from 'react'

import { getTabs, TabDetails, TabId } from '../utils/tabs'
import useSettings from './useSettings'

const useTabs = (): Record<TabId, TabDetails> => {
  const [settings] = useSettings()

  return useMemo(() => {
    return getTabs({
      defaultOptionsMarket: settings?.defaultOptionsMarket,
      defaultPerpsMarket: settings?.defaultPerpsMarket,
      defaultSpotCollateral: settings?.defaultSpotCollateral,
    })
  }, [
    settings?.defaultOptionsMarket,
    settings?.defaultPerpsMarket,
    settings?.defaultSpotCollateral,
  ])
}

export default useTabs
