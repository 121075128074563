import Spinner from '@lyra/core/components/Spinner'
import NextLinkButton from '@lyra/web/components/common/NextLinkButton'
import TrackButton, { TrackButtonProps } from '@lyra/web/components/common/TrackButton'
import { PageId } from '@lyra/web/constants/pages'
import { DepositTokenId } from '@lyra/web/constants/tokens'
import useNewAccount from '@lyra/web/hooks/useNewAccount'
import useTransaction from '@lyra/web/hooks/useTransaction'
import { getPagePath } from '@lyra/web/utils/pages'
import { useCallback, useState } from 'react'

import DepositModal from './DepositModal'

type Props = {
  defaultDepositToken?: DepositTokenId
  subaccountId?: number
  showPending?: boolean
  onClose?: () => void
} & Omit<TrackButtonProps, 'onPress' | 'eventName'>

export default function DepositButton({
  label,
  defaultDepositToken,
  onClose,
  showPending,
  isCta,
  ...props
}: Props) {
  const [isOpen, setIsOpen] = useState(false)
  const handleChangeOpen = useCallback(
    (isOpen: boolean) => {
      if (!isOpen && onClose) {
        onClose()
      }
      setIsOpen(isOpen)
    },
    [onClose]
  )

  const { pendingBridges } = useTransaction()

  const { isAuthWithNoFirstDeposit } = useNewAccount()

  return (
    <>
      {showPending ? (
        <NextLinkButton
          {...props}
          label={`${pendingBridges.length} Pending`}
          href={getPagePath({ page: PageId.Home })}
          icon={<Spinner size={15} />}
        />
      ) : (
        <TrackButton
          {...props}
          eventName={showPending ? 'deposit-button' : undefined}
          label={label ? label : 'Deposit'}
          isCta={isCta !== undefined ? isCta : isAuthWithNoFirstDeposit}
          onPress={(e) => {
            e.stopPropagation()
            handleChangeOpen(true)
          }}
        />
      )}

      {isOpen ? (
        <DepositModal
          onClose={() => handleChangeOpen(false)}
          defaultDepositToken={defaultDepositToken}
        />
      ) : null}
    </>
  )
}
