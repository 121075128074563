export default {
  gray_1: '#141414', // '#111111', // '#171717', //'#111111',
  gray_2: '#232320',
  gray_3: '#333330',
  gray_4: '#444440',
  gray_5: '#545450',
  gray_6: '#65645F',
  gray_7: '#75746F',
  gray_8: '#86857F',
  gray_9: '#96958F',
  gray_10: '#B2B1AB',
  gray_11: '#CDCCC6',
  gray_12: '#E9E8E2',
}
