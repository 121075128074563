'use client'
import { TickerInstrumentNameIntervalPublisherDataSchema } from '@lyra/core/api/types/channel.ticker.instrument_name.interval'
import getTickerFeedChannel, { TICKER_INTERVAL_MS } from '@lyra/core/utils/getTickerFeedChannel'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { Ticker } from '../constants/instruments'
import useWebSocket from '../hooks/useWebSocket'

type Props = {
  children?: React.ReactNode
}

type TickerMap = {
  [instrumentName: string]: Ticker
}

export type TickerContext =
  | {
      isReady: true
      tickers: TickerMap
      subscribeTickers: (instrumentNames: string[]) => void
      unsubscribeTickers: (instrumentNames: string[]) => void
    }
  | {
      isReady: false
      tickers: TickerMap
      subscribeTickers: undefined
      unsubscribeTickers: undefined
    }

// update state every 500ms
const POLL_MS = 500

export const TickerContext = React.createContext<TickerContext>({
  isReady: false,
  tickers: {},
  subscribeTickers: undefined,
  unsubscribeTickers: undefined,
})

function roundNestedNumbers(obj: any): any {
  const newObj: any = Array.isArray(obj) ? [] : {}

  for (const key in obj) {
    const value = obj[key]
    const numValue = parseFloat(value)

    if (typeof value === 'number' || (!isNaN(numValue) && typeof value === 'string')) {
      // round all data to 3dps in comparison, ui never renders more than 3dps of precision
      newObj[key] = Math.floor((typeof value === 'number' ? value : numValue) * 1e3) / 1e3
    } else if (value !== null && typeof value === 'object') {
      newObj[key] = roundNestedNumbers(value)
    } else {
      newObj[key] = value
    }
  }

  return newObj
}

const isDuplicateTicker = (prevTicker: Ticker, newTicker: Ticker) => {
  // remove timestamp from comparison since it is always changing
  const { timestamp: _0, ...newTickerWithoutTimestamp } = newTicker
  const { timestamp: _1, ...prevTickerWithoutTimestamp } = prevTicker
  return (
    JSON.stringify(roundNestedNumbers(newTickerWithoutTimestamp)) ===
    JSON.stringify(roundNestedNumbers(prevTickerWithoutTimestamp))
  )
}

const getChannelName = (instrumentName: string) =>
  getTickerFeedChannel(instrumentName, TICKER_INTERVAL_MS.ONE_THOUSAND)

export default function TickerProvider({ children }: Props) {
  const { isReady, subscribe, unsubscribe } = useWebSocket()
  const [tickers, setTickers] = useState<TickerMap>({})
  const tickersRef = useRef<TickerMap>({})

  const subscribeTickers = useCallback(
    (instrumentNames: string[]): void => {
      if (!isReady || !instrumentNames.length) {
        return
      }
      subscribe(
        instrumentNames.map((instrumentName) => {
          return {
            channel: getChannelName(instrumentName),
            onMessage: (tickerData: TickerInstrumentNameIntervalPublisherDataSchema) => {
              const newTicker = tickerData.instrument_ticker
              tickersRef.current[instrumentName] = newTicker
            },
          }
        })
      )
    },
    [isReady, subscribe]
  )

  const unsubscribeTickers = useCallback(
    (instrumentNames: string[]) => {
      if (!isReady || !instrumentNames.length) {
        return
      }
      const unsubscribeChannels = instrumentNames.map(getChannelName)
      unsubscribe(unsubscribeChannels)
    },
    [isReady, unsubscribe]
  )

  useEffect(() => {
    // ticker updates come in at slightly different times despite throttling
    // batch ticker state updates to reduce renders
    const interval = setInterval(() => {
      setTickers((tickers) => {
        const newTickers = Object.values(tickersRef.current).reduce((map, newTicker) => {
          const instrumentName = newTicker.instrument_name
          const prevTicker: Ticker | undefined = tickers[instrumentName]
          if (prevTicker && isDuplicateTicker(prevTicker, newTicker)) {
            // duplicate data (minus timestamp), maintain ticker reference for rendering performance
            return {
              ...map,
              [instrumentName]: prevTicker,
            }
          }
          // add new ticker reference to updated data
          return {
            ...map,
            [instrumentName]: newTicker,
          }
        }, {})
        return newTickers
      })
    }, POLL_MS)
    return () => clearInterval(interval)
  }, [])

  const value: TickerContext = useMemo(() => {
    return isReady
      ? {
          isReady: true,
          tickers,
          subscribeTickers,
          unsubscribeTickers,
        }
      : {
          isReady: false,
          tickers: {},
          subscribeTickers: undefined,
          unsubscribeTickers: undefined,
        }
  }, [isReady, subscribeTickers, unsubscribeTickers, tickers])

  return <TickerContext.Provider value={value}>{children}</TickerContext.Provider>
}
