import Dropdown from '@lyra/core/components/Dropdown'
import Icon, { Menu, X } from '@lyra/core/components/Icon'
import { useState } from 'react'
import { XStack } from 'tamagui'

import NavMoreDropdownListItems from '../NavMoreDropdownListItems'

const NavMobileTabsMoreButton = () => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <Dropdown
      strategy="fixed"
      trigger={
        <XStack
          flexShrink={1}
          padding="$3"
          $mobile={{ height: '100%' }}
          cursor="pointer"
          justifyContent="center"
          alignItems="center"
          hoverStyle={{
            backgroundColor: '$hoverBg',
          }}
          pressStyle={{
            backgroundColor: '$pressBg',
          }}
        >
          <Icon
            icon={!isOpen ? <Menu /> : <X />}
            size={18}
            color={isOpen ? 'primary' : 'secondary'}
          />
        </XStack>
      }
      minWidth="100vw"
      onChangeOpen={setIsOpen}
      placement="top-end"
    >
      <NavMoreDropdownListItems size="lg" />
    </Dropdown>
  )
}

export default NavMobileTabsMoreButton
