export default {
  red_1: '#241013',
  red_2: '#3E161B',
  red_3: '#591B24',
  red_4: '#73212D',
  red_5: '#8D2735',
  red_6: '#A72D3E',
  red_7: '#C23247',
  red_8: '#DC384F',
  red_9: '#F63E58',
  red_10: '#F3697B',
  red_11: '#F0939D',
  red_12: '#ECBEC0',
}
