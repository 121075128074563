import { usePathname } from 'next/navigation'
import { useMemo } from 'react'

import { removeQueryAndHashFromPathname } from '../utils/pages'
import { getTabIdForPath, TabDetails } from '../utils/tabs'
import useTabs from './useTabs'

const useSelectedTab = (): TabDetails | undefined => {
  const tabs = useTabs()
  const pathname = removeQueryAndHashFromPathname(usePathname())

  const selectedTab = useMemo(() => getTabIdForPath(pathname), [pathname])

  return selectedTab ? tabs[selectedTab] : undefined
}

export default useSelectedTab
