import { WEI_DECIMALS } from '@lyra/core/constants/contracts'
import { useEffect, useMemo } from 'react'
import useSWR from 'swr'
import { Address } from 'viem'

import erc20Abi from '../abis/erc20Abi'
import wrappedErc20Abi from '../abis/wrappedErc20Abi'
import { DepositNetwork } from '../constants/chains'
import { lyraClient } from '../constants/client'
import { lyraContractAddresses } from '../constants/contracts'
import { CollateralId, DepositTokenId } from '../constants/tokens'
import { fetchEstimateNativeBridgeGasFee, fetchSocketBridgeFee } from '../utils/bridge'
import {
  getCollateralAddress,
  getCollateralForDepositToken,
  getLyraTokenAddress,
  getTokenDecimals,
  getTokenForDepositToken,
} from '../utils/tokens'
import useAuth from './useAuth'

export type DepositQuote = {
  fee: bigint
  feeToken: DepositTokenId
  availableDepositCap: bigint
}

const REFRESH_INTERVAL_MS = 30_000

// Available deposit cap of corresponding token/collateral on Lyra Chain
const fetchAvailableDepositCap = async (depositToken: DepositTokenId) => {
  const collateralId = getCollateralForDepositToken(depositToken)
  // USDC has no deposit cap
  if (collateralId === CollateralId.USDC) {
    return BigInt(Number.MAX_SAFE_INTEGER)
  }
  const tokenId = getTokenForDepositToken(depositToken)
  // totalCap in 10^18, totalSupply in token decimal
  const [totalCap, totalSupply] = await Promise.all([
    lyraClient.readContract({
      address: getCollateralAddress(collateralId),
      abi: wrappedErc20Abi,
      functionName: 'totalPositionCap',
      args: [lyraContractAddresses.standardManager],
    }),
    lyraClient.readContract({
      address: getLyraTokenAddress(tokenId),
      abi: erc20Abi,
      functionName: 'totalSupply',
    }),
  ])
  // Adjust to token decimals
  const tokenTotalCap = totalCap / BigInt(10 ** (WEI_DECIMALS - getTokenDecimals(tokenId)))
  return tokenTotalCap - totalSupply
}

const fetchNetworkQuote = async (
  ownerAddress: Address,
  network: DepositNetwork,
  token: DepositTokenId
): Promise<DepositQuote> => {
  const [estGasFee, socketFee, availableDepositCap] = await Promise.all([
    fetchEstimateNativeBridgeGasFee(ownerAddress, network),
    fetchSocketBridgeFee(network, token),
    fetchAvailableDepositCap(token),
  ])

  const fee = socketFee + estGasFee
  return {
    fee,
    feeToken: DepositTokenId.ETH,
    availableDepositCap,
  }
}

export default function useDepositQuote(
  network: DepositNetwork,
  token: DepositTokenId
): {
  data: DepositQuote | undefined
  error: Error
  isLoading: boolean
} {
  const { user } = useAuth()
  const ownerAddress = user?.ownerAddress
  const { data, isLoading, error } = useSWR(
    ownerAddress ? ['DepositQuote', ownerAddress, network, token] : null,
    ([_, ownerAddress, network, token]) => fetchNetworkQuote(ownerAddress, network, token),
    {
      refreshInterval: REFRESH_INTERVAL_MS,
    }
  )

  useEffect(() => {
    if (error) {
      console.error(error)
    }
  }, [error])

  return useMemo(
    () => ({
      data,
      isLoading,
      error,
    }),
    [data, isLoading, error]
  )
}
