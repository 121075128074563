import React from 'react'
import { SVGProps } from 'react'

export function DeriveLogo(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="565"
      height="519"
      viewBox="0 0 565 519"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M556.708 309.818L351.754 0.840434C350.75 -0.659967 348.426 -0.0282201 348.32 1.76173C347.845 9.57961 341.217 51.3276 293.999 51.3276C255.68 51.3276 238.013 44.4573 198.533 44.4573C184.88 44.4573 168.639 52.1699 160.109 65.0681L144 89.3641L298.198 321.768H358.514C376.313 321.768 392.924 330.665 402.721 345.459L439.561 401H565V337.141C565 327.454 562.121 317.951 556.734 309.844L556.708 309.818ZM388.514 177.467C363.743 167.464 344.438 123.689 344.438 123.689C394.456 123.689 428.205 183.1 428.205 183.1C428.205 183.1 413.285 187.469 388.514 177.467Z"
        fill="url(#paint0_linear_214_2)"
      />
      <path
        d="M371 519H145.209C128.993 519.026 113.856 510.89 104.906 497.355L8.76627 351.924C-2.92209 334.255 -2.92209 311.294 8.76627 293.625L115.699 132.053L115.752 132L370.974 518.974L371 519Z"
        fill="url(#paint1_linear_214_2)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_214_2"
          x1="354.5"
          y1="0"
          x2="354.5"
          y2="401"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F63E58" />
          <stop offset="1" stopColor="#FCB124" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_214_2"
          x1="185.5"
          y1="132"
          x2="185.5"
          y2="519"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F63E58" />
          <stop offset="1" stopColor="#FCB124" />
        </linearGradient>
      </defs>
    </svg>
  )
}
