import amber from './colors/amber'
import blue from './colors/blue'
import gray from './colors/gray'
import green from './colors/green'
import red from './colors/red'

const appBg = '#0A0A0A'
const invertedText = appBg

const white = gray.gray_12
const secondary = gray.gray_7

const primaryText = gray.gray_12
const invertedBg = primaryText

const hoverBg = gray.gray_1
const buttonBg = gray.gray_1
const selectBg = gray.gray_1
const hairline = gray.gray_2
const pressBg = gray.gray_2
const disabledBg = gray.gray_2
const secondaryText = gray.gray_9

const bluePrimary = blue.blue_9
const redPrimary = red.red_9
const greenPrimary = green.green_9
const amberPrimary = amber.amber_9

const dark = {
  appBg,
  translucentBg: `${gray.gray_1}80`, // 50%
  appBg90: `${appBg}E6`, // 90%
  appBg50: `${appBg}80`, // 50%
  overlay: '#FFFFFF12',
  hairline,
  hoverBg,
  buttonBg,
  selectBg,
  pressBg,
  disabledBg,
  invertedBg,

  // colors
  white,
  primary: white,
  secondary,
  blue: bluePrimary,
  red: redPrimary,
  green: greenPrimary,
  amber: amberPrimary,

  // texts
  primaryText,
  invertedText,
  secondaryText,

  // blue
  bluePrimary: bluePrimary,
  blueText: bluePrimary,
  blueBg: blue.blue_1,
  blueHairline: blue.blue_2,

  // green
  greenPrimary: greenPrimary,
  greenText: greenPrimary,
  greenBg: green.green_1,
  greenHairline: green.green_2,
  greenPressBg: green.green_3,

  // red
  redPrimary: redPrimary,
  redText: redPrimary,
  redBg: red.red_1,
  redHairline: red.red_2,
  redPressBg: red.red_3,

  // amber
  amberPrimary: amberPrimary,
  amberText: amberPrimary,
  amberBg: amber.amber_1,
  amberHairline: amber.amber_2,

  // misc
  greenLineChart: greenPrimary,
  redLineChart: redPrimary,
  greenAreaChart: green.green_6,
  redAreaChart: red.red_6,
  greenDepthTick: green.green_3,
  redDepthTick: red.red_3,

  // raw colors
  ...gray,
  ...blue,
  ...red,
  ...green,
  ...amber,
}

/**
 * primary_solid
 * primary
 * green_solid
 * green
 * red_solid
 * red
 * transparent
 * disabled
 *
 * properties:
 * baseText
 * baseBg
 * baseBorder
 * hoverBorder
 * activeText
 * activeBg
 * activeBorder
 */

const dark_primary_Button = {
  // default
  text: dark.primaryText,
  textSolid: dark.invertedText,
  textPressed: dark.invertedText,
  bg: dark.buttonBg,
  bgSolid: dark.invertedBg,
  bgPressed: dark.invertedBg,
  bgDisabled: dark.disabledBg,
  bgTransparent: dark.translucentBg,
  border: dark.hairline,
  borderHovered: dark.invertedBg,
  borderPressed: dark.invertedBg,
}

const dark_blue_Button = {
  // default
  text: dark.blueText,
  textPressed: dark.invertedText,
  border: dark.hairline,
  borderHovered: dark.bluePrimary,
  borderPressed: dark.bluePrimary,
  bg: dark.buttonBg,
  bgDisabled: dark.disabledBg,
  bgPressed: dark.bluePrimary,
  bgTransparent: dark.translucentBg,
  // solid
  textSolid: dark.invertedText,
  borderSolid: dark.bluePrimary,
  borderHoveredSolid: dark.bluePrimary,
  borderPressedSolid: dark.bluePrimary,
  bgSolid: dark.bluePrimary,
  bgPressedSolid: dark.bluePrimary,
}

const dark_green_Button = {
  // default
  text: dark.greenText,
  textPressed: dark.invertedText,
  border: dark.hairline,
  borderHovered: dark.greenPrimary,
  borderPressed: dark.greenPrimary,
  bg: dark.buttonBg,
  bgDisabled: dark.disabledBg,
  bgPressed: dark.greenPrimary,
  bgTransparent: dark.translucentBg,
  // solid
  textSolid: dark.invertedText,
  borderSolid: dark.greenPrimary,
  borderHoveredSolid: dark.greenPrimary,
  borderPressedSolid: dark.greenPrimary,
  bgSolid: dark.greenPrimary,
  bgPressedSolid: dark.greenPrimary,
}

const dark_red_Button = {
  // default
  text: dark.redPrimary,
  textPressed: dark.invertedText,
  border: dark.hairline,
  borderHovered: dark.redPrimary,
  borderPressed: dark.redPrimary,
  bg: dark.buttonBg,
  bgDisabled: dark.disabledBg,
  bgPressed: dark.redPrimary,
  bgTransparent: '#0C182080',
  // solid
  textSolid: dark.invertedText,
  borderSolid: dark.redPrimary,
  borderHoveredSolid: dark.redPrimary,
  borderPressedSolid: dark.redPrimary,
  bgSolid: dark.redPrimary,
  bgPressedSolid: dark.redPrimary,
}

const dark_amber_Button = {
  // default
  text: dark.amberPrimary,
  textPressed: dark.invertedText,
  border: dark.hairline,
  borderPressed: dark.hairline,
  borderHovered: dark.amberPrimary,
  bg: dark.buttonBg,
  bgDisabled: dark.disabledBg,
  bgPressed: dark.amberPrimary,
  bgTransparent: '#0C182080',
  // solid
  textSolid: dark.invertedText,
  borderSolid: dark.amber_10,
  borderHoveredSolid: dark.amberPrimary,
  borderPressedSolid: dark.amberPrimary,
  bgSolid: dark.amber_10,
  bgPressedSolid: dark.amber_10,
}

export default {
  // Base
  dark,

  // Buttons
  dark_primary_Button,
  dark_blue_Button,
  dark_green_Button,
  dark_amber_Button,
  dark_red_Button,
}
