import { XStack } from '@lyra/core/components'
import Icon, { DeriveLogo, Pause, Play, Twitter, Warpcast } from '@lyra/core/components/Icon'
import Discord from '@lyra/core/components/Icon/DiscordIcon'
import XSiteIcon from '@lyra/core/components/Icon/XSiteIcon'
import LinkIcon from '@lyra/core/components/LinkIcon'
import Responsive from '@lyra/core/components/Responsive'
import { NAV_MARQUEE_HEIGHT } from '@lyra/web/constants/layout'
import { DISCORD_URL, TWITTER_URL, WARPCAST_URL } from '@lyra/web/constants/urls'
import useSettings from '@lyra/web/hooks/useSettings'
import { useCallback, useRef, useState } from 'react'
import Marquee from 'react-fast-marquee'

import MarqueeItems from '../MarqueeItems'

export default function NavMarquee() {
  const [settings, setSettings] = useSettings()
  const [isTwitterHover, setIsTwitterHover] = useState(false)
  const isMarqueePausedInitially = useRef(settings.isMarqueePaused).current

  const handlePauseMarquee = useCallback(() => {
    setSettings((settings) => ({
      ...settings,
      isMarqueePaused: !settings.isMarqueePaused,
    }))
  }, [setSettings])

  return (
    <XStack
      width="100%"
      borderBottomWidth={1}
      borderColor="$hairline"
      height={NAV_MARQUEE_HEIGHT}
      backgroundColor="$appBg"
    >
      <Responsive
        paddingHorizontal="$2"
        justifyContent="center"
        alignItems="center"
        borderRightWidth={1}
        borderColor="$hairline"
        backgroundColor="$appBg"
        mobile={<Icon icon={<DeriveLogo />} size={16} />}
      />
      <XStack flexGrow={1} flexShrink={1} flexBasis={0}>
        {!isMarqueePausedInitially ? (
          <Marquee
            pauseOnHover
            play={!settings.isMarqueePaused}
            autoFill
            speed={60}
            style={{ overflowY: 'hidden' }}
          >
            <MarqueeItems />
          </Marquee>
        ) : null}
      </XStack>
      <XStack
        paddingHorizontal="$2"
        borderLeftWidth={1}
        borderColor="$hairline"
        gap="$2"
        alignItems="center"
        backgroundColor="$appBg"
      >
        <Responsive
          desktop={
            <LinkIcon
              size={14}
              icon={!!settings.isMarqueePaused ? <Play /> : <Pause />}
              onPress={handlePauseMarquee}
            />
          }
        />
        <LinkIcon size={14} icon={<Warpcast />} href={WARPCAST_URL} target="_blank" />
        <LinkIcon
          size={14}
          onHoverIn={() => setIsTwitterHover(true)}
          onHoverOut={() => setIsTwitterHover(false)}
          icon={isTwitterHover ? <XSiteIcon /> : <Twitter />}
          href={TWITTER_URL}
          target="_blank"
        />
        <LinkIcon size={14} icon={<Discord />} href={DISCORD_URL} target="_blank" />
      </XStack>
    </XStack>
  )
}
