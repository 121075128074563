import { useRouter } from 'next/navigation'
import { useEffect, useMemo } from 'react'

import { TabDetails } from '../utils/tabs'
import useTabs from './useTabs'

/**
 * prefetches routes for first page of each tab
 * @returns sorted list of tabs
 */

const useRenderTabs = (isMobile: boolean): TabDetails[] => {
  const tabs = useTabs()
  const router = useRouter()

  const filteredTabs = useMemo(() => {
    return Object.values(tabs).filter(
      (tab) => (isMobile && tab.isMobile) || (!isMobile && tab.isDesktop)
    )
  }, [isMobile, tabs])

  useEffect(() => {
    const pagePaths = filteredTabs.map((tab) => tab.pages[0].path)
    pagePaths.forEach((path) => router.prefetch(path))
  }, [router, filteredTabs])

  return useMemo(() => filteredTabs, [filteredTabs])
}

export default useRenderTabs
