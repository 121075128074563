import { IncidentResponseSchema } from '@lyra/core/api/types/public.get_live_incidents'
import BodyText from '@lyra/core/components/BodyText'
import Link from '@lyra/core/components/Link'
import Responsive from '@lyra/core/components/Responsive'
import isExternalURL from '@lyra/core/utils/isExternalURL'
import NextLink from '@lyra/web/components/common/NextLink'
import { SITE_WIDE_NOTICE_HEIGHT } from '@lyra/web/constants/layout'
import useLiveIncidents from '@lyra/web/hooks/useLiveIncidents'
import useSiteWideAnnouncements from '@lyra/web/hooks/useSiteWideAnnouncements'
import { useMemo } from 'react'
import Marquee from 'react-fast-marquee'
import { isServer, useTheme, XStack } from 'tamagui'

export default function NavBanner() {
  const { incidents, dismissIncident, isLoadingIncidents } = useLiveIncidents()
  const { activeAnnouncement, dismissAnnouncement, isLoadingAnnouncements } =
    useSiteWideAnnouncements()
  const theme = useTheme()

  const highestSeverityIncident: IncidentResponseSchema = useMemo(
    () => incidents?.sort((a) => (a.severity === 'high' ? -1 : a.severity === 'medium' ? 0 : 1))[0],
    [incidents]
  )

  if (isServer || isLoadingIncidents || isLoadingAnnouncements) {
    return null
  }

  return highestSeverityIncident ? (
    <XStack
      paddingHorizontal="$3"
      paddingVertical="$1.5"
      alignItems="center"
      width="100%"
      minHeight={SITE_WIDE_NOTICE_HEIGHT}
      backgroundColor="$amberBg"
    >
      <BodyText marginHorizontal="auto" textAlign="center">
        {highestSeverityIncident.message}
      </BodyText>
      {highestSeverityIncident.severity !== 'high' ? (
        <Link size="md" label="Dismiss" onPress={() => dismissIncident(highestSeverityIncident)} />
      ) : null}
    </XStack>
  ) : activeAnnouncement ? (
    <Responsive
      mobile={
        <XStack
          width="100%"
          paddingVertical="$1.5"
          minHeight={SITE_WIDE_NOTICE_HEIGHT}
          background={`linear-gradient(90deg, ${theme.red_9?.get()}, ${theme.amber_9?.get()})`}
        >
          <Marquee gradient={false} speed={40}>
            <BodyText color="inverted" textAlign="center">
              {activeAnnouncement.message}{' '}
              {activeAnnouncement.href ? (
                isExternalURL(activeAnnouncement.href) ? (
                  <Link
                    color="inverted"
                    label="Learn more"
                    href={activeAnnouncement.href}
                    target="_blank"
                  />
                ) : (
                  <NextLink
                    color="inverted"
                    label="Learn more"
                    href={activeAnnouncement.href as `/${string}`}
                  />
                )
              ) : null}
            </BodyText>
          </Marquee>
          {activeAnnouncement.isDismissible && (
            <Link
              color="inverted"
              size="md"
              label="Dismiss"
              paddingHorizontal="$2"
              onPress={() => dismissAnnouncement(activeAnnouncement.id)}
            />
          )}
        </XStack>
      }
      desktop={
        <XStack
          width="100%"
          paddingVertical="$1.5"
          alignItems="center"
          justifyContent="space-between"
          minHeight={SITE_WIDE_NOTICE_HEIGHT}
          background={`linear-gradient(90deg, ${theme.red_9?.get()}, ${theme.amber_9?.get()})`}
        >
          <XStack flex={1} justifyContent="center">
            <BodyText color="inverted" numberOfLines={1}>
              {activeAnnouncement.message}{' '}
              {activeAnnouncement.href ? (
                isExternalURL(activeAnnouncement.href) ? (
                  <Link
                    color="inverted"
                    label="Learn more"
                    href={activeAnnouncement.href}
                    target="_blank"
                  />
                ) : (
                  <NextLink
                    color="inverted"
                    label="Learn more"
                    href={activeAnnouncement.href as `/${string}`}
                  />
                )
              ) : null}
            </BodyText>
          </XStack>
          {activeAnnouncement.isDismissible && (
            <Link
              color="inverted"
              size="md"
              label="Dismiss"
              paddingHorizontal="$2"
              onPress={() => dismissAnnouncement(activeAnnouncement.id)}
            />
          )}
        </XStack>
      }
    />
  ) : null
}
