import { NotificationResponseSchema } from '../../../core/api/types/private.get_notifications'
import { OrderType } from './order'

export enum NotificationEvent {
  Trade = 'trade',
  Settlement = 'settlement',
  Liquidation = 'liquidation',
}

export type PartialNotification = Omit<
  NotificationResponseSchema,
  'status' | 'event' | 'event_details'
> &
  (
    | {
        event: NotificationEvent.Settlement
        event_details: {
          amount: number
          expiry: number
          instrument_name: string
          option_settlement_pnl: string
          settlement_price: string
        }
      }
    | {
        event: NotificationEvent.Trade
        event_details: {
          direction: 'buy' | 'sell'
          instrument_name: string
          is_partial_fill: boolean
          limit_price: string
          order_amount: string
          order_type: OrderType
          realized_pnl: string
          subaccount_id: number
          trade_amount: string
          trade_id: string
          trade_price: string
          tx_status: 'settled' | 'reverted'
        }
      }
    | {
        event: NotificationEvent.Liquidation
        // TODO: @earthtojake add event details
        event_details: null
      }
  )

export type Notification = PartialNotification & {
  isSeen: boolean
}

// mark all notifications before this timestamp as read
// useful for migrations
export const NOTIFICATIONS_MARK_AS_READ_TIMESTAMP = 1709638926000

export const NOTIFICATIONS_START_TIMESTAMP = 1706706000000

export const NOTIFICATIONS_DEFAULT_LAST_SEEN_TIMESTAMP = 1726796399000
