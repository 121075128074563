import Icon, { AlertTriangle, HelpCircle } from '@lyra/core/components/Icon'
import { Notification, NotificationEvent } from '@lyra/web/constants/notifications'
import { parseInstrumentName } from '@lyra/web/utils/instruments'
import { getNotificationIsReverted } from '@lyra/web/utils/notifications'
import React from 'react'

import TokenIcon from './TokenIcon'

type Props = {
  notification: Notification
}

export const getNotificationIcon = (notification: Notification) => {
  switch (notification.event) {
    case NotificationEvent.Trade:
    case NotificationEvent.Settlement:
      const instrument = parseInstrumentName(notification.event_details.instrument_name)
      if (!instrument) {
        return <Icon size={16} icon={<HelpCircle />} />
      }
      const isReverted = getNotificationIsReverted(notification)
      if (isReverted) {
        return <Icon size={16} icon={<AlertTriangle />} />
      }
      return <TokenIcon size={16} symbol={instrument.marketId} />
    case NotificationEvent.Liquidation:
      return <Icon size={16} icon={<AlertTriangle />} />
  }
}

const NotificationIcon = ({ notification }: Props) => {
  return getNotificationIcon(notification)
}

export default NotificationIcon
