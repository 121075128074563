import VideoText from '@lyra/core/components/VideoText'
import { NAV_MOBILE_TABS_HEIGHT } from '@lyra/web/constants/layout'
import useRenderTabs from '@lyra/web/hooks/useRenderTabs'
import useSelectedTab from '@lyra/web/hooks/useSelectedTab'
import { useRouter } from 'next/navigation'
import { Stack, XStack } from 'tamagui'

import NavMobileTabsMoreButton from './NavMobileTabsMoreButton'

export default function NavMobileTabs() {
  const tabs = useRenderTabs(true)
  const selectedTab = useSelectedTab()

  const router = useRouter()

  return (
    <XStack
      width="100%"
      justifyContent="center"
      alignItems="center"
      height={NAV_MOBILE_TABS_HEIGHT}
      style={{
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
      }}
      zIndex={10}
      backgroundColor="$appBg"
      borderTopWidth={1}
      borderColor="$hairline"
    >
      <NavMobileTabsMoreButton />
      {tabs.map((tab) => (
        <Stack
          key={tab.name}
          flexGrow={1}
          flexShrink={1}
          padding="$3"
          cursor="pointer"
          onPress={() => router.push(tab.pages[0].path)}
          hoverStyle={{
            backgroundColor: '$hoverBg',
          }}
          justifyContent="center"
          alignItems="center"
          height="100%"
        >
          <VideoText color={selectedTab && selectedTab.id === tab.id ? 'primary' : 'secondary'}>
            {tab.name}
          </VideoText>
        </Stack>
      ))}
    </XStack>
  )
}
