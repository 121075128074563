import Dropdown from '@lyra/core/components/Dropdown'
import Icon, { ChevronDown, ChevronUp } from '@lyra/core/components/Icon'
import VideoLink from '@lyra/core/components/VideoLink'
import useHover from '@lyra/core/hooks/useHover'
import { useState } from 'react'
import { XStack } from 'tamagui'

import NavMoreDropdownListItems from '../NavMoreDropdownListItems'

const NavDesktopHeaderMoreLink = () => {
  const { isHovering, handleHoverIn, handleHoverOut } = useHover()
  const [isOpen, setIsOpen] = useState(false)

  const isActive = isHovering || isOpen

  return (
    <Dropdown
      strategy="fixed"
      trigger={
        <XStack
          onHoverIn={handleHoverIn}
          onHoverOut={handleHoverOut}
          gap="$0.5"
          alignItems="center"
          cursor="pointer"
        >
          <VideoLink isActive={isActive} label="MORE" size="md" />
          <Icon
            icon={isOpen ? <ChevronUp strokeWidth={3} /> : <ChevronDown strokeWidth={3} />}
            size={12}
            color={isActive ? 'primary' : 'secondary'}
          />
        </XStack>
      }
      onChangeOpen={setIsOpen}
      placement="bottom-start"
    >
      <NavMoreDropdownListItems />
    </Dropdown>
  )
}

export default NavDesktopHeaderMoreLink
