export const ANIMATION_REGULAR_TRANSITION_MS = 150
export const ANIMATION_LONG_TRANSITION_MS = 250

export const animations = {
  'ease-regular': `${ANIMATION_REGULAR_TRANSITION_MS}ms ease`,
  'ease-regular-long': `1500ms ease`,
  'ease-out-cubic': '0.5s cubic-bezier(0.33, 1, 0.68, 1)',
  'level-ease': `${1000}ms cubic-bezier(0.76, 0, 0.24, 1)`, // https://easings.net/#easeInOutQuart
  collapsible: '150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
}
