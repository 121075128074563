import Button from '@lyra/core/components/Button'
import Input from '@lyra/core/components/Input'
import Modal from '@lyra/core/components/Modal'
import Notice from '@lyra/core/components/Notice'
import Section from '@lyra/core/components/Section'
import { USERNAME_REGEX } from '@lyra/web/constants/user'
import useAuth from '@lyra/web/hooks/useAuth'
import { useCallback, useState } from 'react'

type Props = {
  onClose: () => void
}

const EditUsernameModal = ({ onClose }: Props) => {
  const { user, updateUsername } = useAuth()
  const [usernameInput, setUsernameInput] = useState('')

  const isValid = USERNAME_REGEX.test(usernameInput)

  const handleUpdateUsername = useCallback(
    async (username: string | null) => {
      if (username && !USERNAME_REGEX.test(username)) {
        return
      }
      await updateUsername(username?.length ? username.toLowerCase() : null)
      onClose()
    },
    [onClose, updateUsername]
  )

  return (
    <Modal
      onClose={onClose}
      title="Edit Username"
      subtitle="Update your wallet's username. Your username will appear on public leaderboards."
      isLarge
    >
      <Section.XStack>
        <Input
          label="Username"
          size="lg"
          width="100%"
          placeholder={user?.username ?? 'Enter Username'}
          value={usernameInput}
          onChangeValue={setUsernameInput}
        />
      </Section.XStack>
      <Section.YStack>
        {!isValid && usernameInput.length ? (
          <Notice
            message="Usernames must be 4-14 characters, numbers or symbols with no spaces."
            onPress={() => handleUpdateUsername(usernameInput)}
            width="100%"
          />
        ) : null}
        <Button
          isSolid
          color="cta"
          label={
            !usernameInput ? 'Enter Username' : !isValid ? 'Invalid Username' : 'Update Username'
          }
          isDisabled={!usernameInput || !isValid}
          size="lg"
          onPress={() => handleUpdateUsername(usernameInput)}
        />
        {user?.username && (
          <Button
            size="lg"
            label="Remove Username"
            color="red"
            onPress={() => handleUpdateUsername(null)}
          />
        )}
      </Section.YStack>
    </Modal>
  )
}

export default EditUsernameModal
