import BodyText from '@lyra/core/components/BodyText'
import { ButtonProps } from '@lyra/core/components/Button'
import Heading from '@lyra/core/components/Heading'
import { Bell } from '@lyra/core/components/Icon'
import Spinner from '@lyra/core/components/Spinner'
import useIsMobile from '@lyra/core/hooks/useIsMobile'
import {
  NAV_MOBILE_HEIGHT,
  NAV_MOBILE_TABS_HEIGHT,
  SECTION_SPINNER_SIZE,
} from '@lyra/web/constants/layout'
import useNotification from '@lyra/web/hooks/useNotification'
import React, { useMemo, useState } from 'react'
import { XStack, YStack } from 'tamagui'

import NavDropdownButton from '../../../../components/common/NavDropdownButton'
import NavNotificationListItem from './NavNotificationListItem'

type Props = ButtonProps

const NavNotificationDropdownButton = ({ ...buttonProps }: Props) => {
  const { notifications, isLoading, error, updateSeenNotifications } = useNotification()

  const unseenNotifications = useMemo(
    () => notifications.filter((notif) => !notif.isSeen),
    [notifications]
  )

  const filteredNotifications = useMemo(() => notifications.slice(0, 25), [notifications])

  const [isDropdownOpen, setIsDropdownOpen] = useState(false)

  const numUnseenNotifications =
    unseenNotifications.length && !isDropdownOpen ? unseenNotifications.length : 0

  const isMobile = useIsMobile()

  return (
    <NavDropdownButton
      {...buttonProps}
      label={numUnseenNotifications ? numUnseenNotifications : undefined}
      color={numUnseenNotifications ? 'red' : undefined}
      isSolid={!!numUnseenNotifications}
      hideChevron
      icon={!numUnseenNotifications ? <Bell /> : undefined}
      dropdownProps={{
        maxHeight: isMobile
          ? `calc(100svh - ${NAV_MOBILE_HEIGHT + NAV_MOBILE_TABS_HEIGHT - 2 * 6}px)`
          : 600,
        minHeight: isMobile
          ? `calc(100svh - ${NAV_MOBILE_HEIGHT + NAV_MOBILE_TABS_HEIGHT - 2 * 6}px)`
          : 280,
        minWidth: isMobile ? '100vw' : 280,
      }}
      onChangeOpen={(isOpen) => {
        if (!isOpen) {
          updateSeenNotifications()
        }
        setIsDropdownOpen(isOpen)
      }}
    >
      <XStack
        width="100%"
        padding="$2"
        style={{ position: 'sticky', top: 0 }}
        backgroundColor="$hoverBg"
        borderBottomColor="$hairline"
        borderBottomWidth={1}
        zIndex={1}
      >
        <Heading size="h3">Notifications</Heading>
      </XStack>
      <YStack width="100%" flexGrow={1} flexShrink={1}>
        {filteredNotifications.length ? (
          filteredNotifications.map((notification) => (
            <NavNotificationListItem key={notification.id} notification={notification} />
          ))
        ) : (
          <YStack width="100%" height="100%" alignItems="center" justifyContent="center">
            {error ? (
              <BodyText color="secondary">Failed to load notifications</BodyText>
            ) : isLoading ? (
              <Spinner size={SECTION_SPINNER_SIZE} color="primary" />
            ) : (
              <YStack minHeight={180} justifyContent="center">
                <BodyText color="secondary">No notifications</BodyText>
              </YStack>
            )}
          </YStack>
        )}
      </YStack>
    </NavDropdownButton>
  )
}

export default NavNotificationDropdownButton
