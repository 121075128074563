'use client'

import Button from '@lyra/core/components/Button'
import Input from '@lyra/core/components/Input'
import Notice from '@lyra/core/components/Notice'
import PageNarrowCentered from '@lyra/core/components/PageNarrowCentered'
import Section from '@lyra/core/components/Section'
import { useCallback, useState } from 'react'

import useAdmin from '../../hooks/useAdmin'

export default function DeployPasswordPageHelper() {
  const [value, setValue] = useState('')
  const [error, setError] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const { unlockDeploy } = useAdmin()

  const handleSubmit = useCallback(() => {
    setIsLoading(true)
    unlockDeploy(value)
      .then(() => {
        setIsLoading(false)
      })
      .catch((error) => {
        console.error(error)
        setError(true)
        setValue('')
        setIsLoading(false)
      })
  }, [unlockDeploy, value])

  return (
    <PageNarrowCentered>
      <Section noTopBorder>
        <Section.Header title="Protected" subtitle="This page is protected" />
        <Section.YStack>
          <Input
            width="100%"
            isPassword
            placeholder="Password"
            size="lg"
            value={value}
            onChangeValue={(value) => {
              setValue(value)
              if (error) {
                setError(false)
              }
            }}
            autoFocus
            onSubmit={handleSubmit}
            returnKeyType="send"
          />
        </Section.YStack>
        <Section.YStack>
          {error ? <Notice message="Incorrect password" status="error" width="100%" /> : null}
          <Button
            isSolid
            size="lg"
            onPress={handleSubmit}
            label="Submit"
            color="cta"
            isLoading={isLoading}
          />
        </Section.YStack>
      </Section>
    </PageNarrowCentered>
  )
}
