const media = {
  md: {
    maxWidth: 768,
  },
  mobile: {
    maxWidth: 768,
  },
  laptop: {
    minWidth: 768 + 1,
    maxWidth: 1200,
  },
  desktop: {
    minWidth: 768 + 1,
  },
  monitor: {
    minWidth: 1920 + 1,
  },
}

export default media
