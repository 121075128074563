import {
  SECONDS_IN_DAY,
  SECONDS_IN_HOUR,
  SECONDS_IN_MINUTE,
  SECONDS_IN_WEEK,
  SECONDS_IN_YEAR,
} from '../constants/time'

type FormatRelativeDateTimeOptions = {
  now?: Date
  truncate?: boolean
}

const formatRelativeDateTime = (
  timestamp: Date,
  options?: FormatRelativeDateTimeOptions
): string => {
  const now = options?.now ?? new Date()
  const truncate = !!options?.truncate

  const diffMsSign = now.getTime() - timestamp.getTime()
  const diffMs = Math.abs(diffMsSign)

  if (diffMsSign >= 0) {
    const suffix = !truncate ? ' Ago' : ''

    if (diffMs >= SECONDS_IN_YEAR * 1000) {
      const diffInYears = Math.floor(diffMs / (SECONDS_IN_YEAR * 1000))
      return `${diffInYears}y${suffix}`
    }

    if (diffMs >= SECONDS_IN_WEEK * 1000) {
      const diffInWeeks = Math.floor(diffMs / (SECONDS_IN_WEEK * 1000))
      return `${diffInWeeks}w${suffix}`
    }

    if (diffMs >= SECONDS_IN_DAY * 1000) {
      const diffInDays = Math.floor(diffMs / (SECONDS_IN_DAY * 1000))
      return `${diffInDays}d${suffix}`
    }

    if (diffMs >= SECONDS_IN_HOUR * 1000) {
      const diffInHours = Math.floor(diffMs / (SECONDS_IN_HOUR * 1000))
      return `${diffInHours}h${suffix}`
    }

    if (diffMs >= SECONDS_IN_MINUTE * 1000) {
      const diffInMinutes = Math.floor(diffMs / (SECONDS_IN_MINUTE * 1000))
      return `${diffInMinutes}m${suffix}`
    }

    if (diffMs >= 15 * 1000) {
      const diffInSeconds = Math.floor(diffMs / 1000)
      return `${diffInSeconds}s${suffix}`
    }

    // < 15s ago
    return 'Now'
  } else {
    const prefix = !truncate ? 'In ' : ''

    if (diffMs >= SECONDS_IN_YEAR * 1000) {
      const diffInYears = Math.floor(diffMs / (SECONDS_IN_YEAR * 1000))
      return `${prefix}${diffInYears}y`
    }

    if (diffMs >= SECONDS_IN_WEEK * 1000) {
      const diffInWeeks = Math.floor(diffMs / (SECONDS_IN_WEEK * 1000))
      return `${prefix}${diffInWeeks}w`
    }

    if (diffMs >= SECONDS_IN_DAY * 1000) {
      const diffInDays = Math.ceil(diffMs / (SECONDS_IN_DAY * 1000))
      return `${prefix}${diffInDays}d`
    }

    if (diffMs >= SECONDS_IN_HOUR * 1000) {
      const diffInHours = Math.ceil(diffMs / (SECONDS_IN_HOUR * 1000))
      return `${prefix}${diffInHours}h`
    }

    if (diffMs >= SECONDS_IN_MINUTE * 1000) {
      const diffInMinutes = Math.ceil(diffMs / (SECONDS_IN_MINUTE * 1000))
      return `${prefix}${diffInMinutes}m`
    }

    if (diffMs >= 15 * 1000) {
      const diffInSeconds = Math.ceil(diffMs / 1000)
      return `${prefix}${diffInSeconds}s`
    }

    // < 15 s ago
    return 'Now'
  }
}

export default formatRelativeDateTime
