import { createAnimations } from '@tamagui/animations-css'
import { createTamagui } from 'tamagui'

import { animations } from './constants/animations'
import fonts from './constants/fonts'
import media from './constants/media'
import themes from './constants/themes'
import tokens from './constants/tokens'

export const config = createTamagui({
  // !IMPORTANT animations (even if empty) is required for toasts to work
  animations: createAnimations(animations),
  tokens,
  themes,
  defaultFont: 'body',
  fonts,
  media,
})
