import { createFont } from 'tamagui'

const body = createFont({
  family: 'GT Standard',
  size: {
    sm: 11,
    md: 13,
    lg: 16,
    editorial: 20,
  },
  lineHeight: {
    sm: 17,
    md: 19,
    lg: 22,
    editorial: 26,
  },
})

const display = createFont({
  family: 'GT Standard Display',
  size: {
    h1: 64,
    display: 96,
  },
  lineHeight: {
    h1: 72,
    display: 110,
  },
})

const heading = createFont({
  family: 'GT Standard Heading',
  size: {
    h4: 16,
    h3: 22,
    h2: 32,
  },
  lineHeight: {
    h4: 22,
    h3: 28,
    h2: 38,
  },
})

const special = createFont({
  family: 'GT Standard Mono',
  size: {
    sm: 11,
    md: 12,
    lg: 14,
  },
  letterSpacing: {
    sm: 1,
    md: 1.1,
    lg: 1.2,
  },
  lineHeight: {
    sm: 17,
    md: 18,
    lg: 20,
  },
})

const specialBold = createFont({
  family: 'GT Standard Mono Bold',
  size: {
    sm: 11,
    md: 12,
    lg: 14,
  },
  letterSpacing: {
    sm: 1,
    md: 1.1,
    lg: 1.2,
  },
  lineHeight: {
    sm: 17,
    md: 18,
    lg: 20,
  },
})

export default {
  special,
  specialBold,
  body,
  heading,
  display,
}
