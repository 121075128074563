import Link, { LinkProps } from '@lyra/core/components/Link'
import { Pathname } from '@lyra/web/constants/pages'
import RouteLink from 'next/link'
import { useRouter } from 'next/navigation'
import { useEffect } from 'react'

type Props = {
  href: Pathname
} & Omit<LinkProps, 'href' | 'target'>

const NextLink = ({ href, ...linkProps }: Props) => {
  const { prefetch } = useRouter()

  useEffect(() => {
    prefetch(href)
  }, [prefetch, href])

  return (
    <RouteLink href={href} style={{ textDecoration: 'none' }} legacyBehavior>
      <Link href={href} {...linkProps} />
    </RouteLink>
  )
}

export default NextLink
