'use client'

import '@tamagui/core/reset.css'
import '@tamagui/polyfill-dev'
import '@lyra/core/global.css'

import { TamaguiProvider as TamaguiProviderOG, Theme } from '@lyra/core/components'
import { config } from '@lyra/core/tamagui.config'
import { ToastProvider as TamaguiToastProvider } from '@tamagui/toast'
import { useServerInsertedHTML } from 'next/navigation'
import React from 'react'
import { StyleSheet } from 'react-native'

import ToastProvider from './ToastProvider'

type Props = {
  children: React.ReactNode
}

// App Router compatible - requires 'use client' directive
const ThemeProvider = ({ children }: Props) => {
  useServerInsertedHTML(() => {
    // eslint-disable-next-line
    // @ts-ignore
    const rnwStyle = StyleSheet.getSheet()

    return (
      <>
        <style dangerouslySetInnerHTML={{ __html: config.getCSS() }} />
        <style dangerouslySetInnerHTML={{ __html: rnwStyle.textContent }} id={rnwStyle.id} />
      </>
    )
  })

  return (
    <TamaguiProviderOG config={config} disableRootThemeClass defaultTheme="dark">
      <TamaguiToastProvider>
        <Theme name="dark">
          <ToastProvider>{children}</ToastProvider>
        </Theme>
      </TamaguiToastProvider>
    </TamaguiProviderOG>
  )
}

export default ThemeProvider
