import { LyraAuthHeaders } from '@lyra/core/constants/api'

import tryRequest, { RequestOptions } from '../tryRequest'
import {
  PrivateGetNotificationsParamsSchema,
  PrivateGetNotificationsResponseSchema,
} from '../types/private.get_notifications'

export default async function fetchNotifications(
  params: PrivateGetNotificationsParamsSchema,
  authHeaders: LyraAuthHeaders,
  options?: RequestOptions
): Promise<PrivateGetNotificationsResponseSchema> {
  return await tryRequest<
    PrivateGetNotificationsParamsSchema,
    PrivateGetNotificationsResponseSchema
  >('/private/get_notifications', params, { ...options, authHeaders })
}
