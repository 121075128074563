import fetchNotificationsApi from '@lyra/core/api/private/fetchNotifications'
import { LyraAuthHeaders } from '@lyra/core/constants/api'
import { WEI_DECIMALS } from '@lyra/core/constants/contracts'
import { bigNumberToNumberUNSAFE } from '@lyra/core/utils/bigNumberToNumberUNSAFE'
import { PageId, Pathname } from '@lyra/web/constants/pages'
import { getPagePath } from '@lyra/web/utils/pages'
import { Address } from 'viem'

import { HistoryTab } from '../constants/history'
import {
  NotificationEvent,
  NOTIFICATIONS_START_TIMESTAMP,
  PartialNotification,
} from '../constants/notifications'
import { formatInstrumentWithSize } from './instruments'
import { coerce } from './types'

const PAGE_SIZE = 50 // max page size

export const getIsNotificationSeen = (
  notification: PartialNotification,
  lastSeenTimestamp: number
) => {
  if (notification.timestamp <= lastSeenTimestamp) {
    return true
  }

  // skip tx confirmed trade notifications (show reverts)
  if (
    notification.event === NotificationEvent.Trade &&
    notification.event_details.tx_status === 'settled'
  ) {
    return true
  }

  return false
}

export const fetchLatestNotifications = async (
  address: Address,
  authHeaders: LyraAuthHeaders
): Promise<PartialNotification[]> => {
  const params = {
    wallet: address,
    type: Object.values(NotificationEvent),
    page_size: PAGE_SIZE,
    page: 1,
  }

  const { result } = await fetchNotificationsApi(params, authHeaders)
  const notifications = result.notifications

  return notifications
    .filter((notif) => notif.timestamp >= NOTIFICATIONS_START_TIMESTAMP)
    .filter((notif) => !!coerce(NotificationEvent, notif.event))
    .sort((a, b) => b.timestamp - a.timestamp) as unknown as PartialNotification[]
}

export const getNotificationTitle = (notification: PartialNotification): string => {
  switch (notification.event) {
    case NotificationEvent.Trade:
      const buyOrSell = notification.event_details.direction === 'buy' ? 'Buy' : 'Sell'
      const orderAmount = Math.abs(+notification.event_details.trade_amount)
      const tradeInstrumentName = notification.event_details.instrument_name
      let notifStr = `${buyOrSell} ${formatInstrumentWithSize(tradeInstrumentName, orderAmount, {
        showExpiry: true,
      })}`
      if (notification.event_details.tx_status === 'reverted') {
        notifStr += ' · Failed'
      }
      return notifStr
    case NotificationEvent.Settlement:
      return 'Option Expired'
    case NotificationEvent.Liquidation:
      return 'Liquidation'
  }
}

export const getNotificationDescription = (notification: PartialNotification): string => {
  switch (notification.event) {
    case NotificationEvent.Settlement:
      const settleAmount = Math.abs(
        bigNumberToNumberUNSAFE(notification.event_details.amount.toString(), WEI_DECIMALS)
      )
      const settleInstrumentName = notification.event_details.instrument_name
      return formatInstrumentWithSize(settleInstrumentName, settleAmount, { showExpiry: true })
    case NotificationEvent.Trade:
      return notification.event_details.order_type === 'limit' ? 'Limit Order' : 'Market Order'
    case NotificationEvent.Liquidation:
      return 'Your trading account was liquidated'
  }
}

export const getNotificationPath = (notification: PartialNotification): Pathname => {
  switch (notification.event) {
    case NotificationEvent.Liquidation:
    case NotificationEvent.Trade:
    case NotificationEvent.Settlement:
      return getPagePath({ page: PageId.History, tab: HistoryTab.Trades })
  }
}

export const getNotificationIsReverted = (notification: PartialNotification): boolean => {
  switch (notification.event) {
    case NotificationEvent.Trade:
      return notification.event_details.tx_status === 'reverted'
    case NotificationEvent.Settlement:
    case NotificationEvent.Liquidation:
      return false
  }
}
